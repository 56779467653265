import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'
import TextSingleBlock from '../components/project-blocks/text-single'
import SVGSeparator from '../components/project-blocks/separator'
import ImageDoubleBlock from '../components/project-blocks/image-double'
import ImageSingleBlock from '../components/project-blocks/image-single'
import TextAndImage from '../components/project-blocks/text-and-image'
import InformationSectionBlock from '../components/project-blocks/information-section'

import { informationSectionData } from '../data/project-data--ysdn2019-teaser'

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 24px;

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 1200px) {
		max-width: 1200px;
	}
`

const TestPage = ({ data }) => (
	<Layout>
		<Container>
			<TextLargeAndSmallBlock />
			<SVGSeparator />
			<ImageDoubleBlock
				imageData={[data.queriedImageOne, data.queriedImageTwo]}
				altText={['An image I\'m testing.', 'Another image I\'m testing.']}
				contain={true}
			/>
			<SVGSeparator />
			<TextSingleBlock />
			<SVGSeparator />
			<ImageSingleBlock
				imageData={data.queriedImageThree}
				altText={'An image I\'m testing.'}
				contain={true}
			/>
			<SVGSeparator />
			<InformationSectionBlock data={informationSectionData} />
			<SVGSeparator />
			<TextAndImage
				heading={'An Example Heading'}
				paragraphs={[
					'Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 Paragraph1 ',
					'Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2 Paragraph2'
				]}
				imageData={data.queriedImageThree}
				altText={'An image I\'m testing.'}
				contain={true}
			/>
			<SVGSeparator />
		</Container>
	</Layout>
)

TestPage.propTypes = {
	data: PropTypes.any.isRequired
}

export default TestPage

export const query = graphql`
	query ImageBlockQueries {
		queriedImageThree: file(
			relativePath: { regex: "/apple-watch-apps--today-redesign.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		queriedImageOne: file(relativePath: { regex: "/castle--old-onboarding/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		queriedImageTwo: file(relativePath: { regex: "/castle--new-onboarding/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`
